const ROUTES = {
  BASE: 'https://panda.com.pe',
  ROOT: '/',
  CUSTOMER_SUPPORT_ROOT: '/atencion-al-cliente',
  //CUSTOMER_SUPPORT_TARIFARIO: '/archivo/tarifario',
  CUSTOMER_SUPPORT_RECARGA_RETIRO: '/archivo/recarga-retiro',
  CUSTOMER_SUPPORT_LIMTES: '/atencion-al-cliente/limites-y-restricciones',
  CUSTOMER_SUPPORT_CONSULTAS: '/atencion-al-cliente/consultas-y-reclamos',
  CUSTOMER_SUPPORT_LIBRO: '/atencion-al-cliente/libro-de-reclamaciones',
  CUSTOMER_SUPPORT_PROCEDIMIENTOS: '/atencion-al-cliente/procedimientos-de-consultas-y-reclamos',
  CUSTOMER_SUPPORT_CONTRATO: '/atencion-al-cliente/descarga-tu-contrato',
  CUSTOMER_SUPPORT_CONTRATO_POLITICAS: '/archivo/politica-de-uso-de-datos-personales',
  CUSTOMER_SUPPORT_CONTRATO_POLITICAS_PRIVACIDAD: '/archivo/politica-de-privacidad',
  CUSTOMER_SUPPORT_CONTRATO_CUENTA: '/archivo/contrato-cliente-cuenta-simple',
  CUSTOMER_SUPPORT_CLIENTES: '/atencion-al-cliente/clientes-cuenta-general',
  CUSTOMER_SUPPORT_SERVICIOS: '/atencion-al-cliente/lista-de-servicios',
  CUSTOMER_SUPPORT_SERVICIOS_FILE: '/archivo/lista-de-servicios',
  CUSTOMER_SUPPORT_PREGUNTAS: '/atencion-al-cliente/preguntas-frecuentes',
  CUSTOMER_SUPPORT_CASO_ROBO: '/atencion-al-cliente/caso-de-robo',
  CUSTOMER_SUPPORT_TARIFARIO: '/atencion-al-cliente/tarifario',
  TRANSPARENCY_ROOT: '/transparencia',
  TRANSPARENCY_SBS: '/transparencia/portal-sbs',
  TRANSPARENCY_REGISTRO_RECLAMOS: '/transparencia/registro-reclamos',
  RECLAMOS_TRIMESTRE_1_2021: '/archivo/reclamos-trimestre-1-2021',
  RECLAMOS_TRIMESTRE_2_2021: '/archivo/reclamos-trimestre-2-2021',
  REPORT_PRACTICES: '/denuncia-practicas-cuestionables',
  HOME_PROMOTION_FILE: '/archivo/devuelta-kontigo',
  SUBSIDIOS_ESSALUD: '/archivo/subsidios-essalud',
  PROMOCIONES_KONCABIFY: '/kon-cabify',
  POLITICAS_DE_PRIVACIDAD: '/politicas-de-privacidad',
}

export default ROUTES
