import React, { FunctionComponent } from 'react'
import { ReactComponent as IconWhatsApp } from 'assets/images/svg/whatsapp-web.svg'
import styles from './WhatApp.module.scss'

const WhatsAppWidget: FunctionComponent = () => {
  return (
    <div className={styles.whatsappContainer}>
      <a
        href="https://api.whatsapp.com/send?phone=51981701010&text=Hola!%20Tengo%20una%20consulta%2C%20%C2%BFpueden%20ayudarme%3F"
        target="_blank"
        rel="noopener noreferrer"
        title="Envíanos un mensaje"
        className={styles.iconFloat}
      >
        <IconWhatsApp />
      </a>
    </div>
  )
}

export default WhatsAppWidget
