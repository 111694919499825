import React from 'react'
import LoaderIcon from './LoaderIcon'
import styles from './Loader.module.scss'

export function Loader() {
  return (
    <div className={styles.kloader}>
      <LoaderIcon />
    </div>
  )
}
