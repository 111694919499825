import React, { FunctionComponent, Suspense } from 'react'
import { Route } from 'wouter'
import ROUTES from 'constans/routes'
import 'scss/global.scss'
import WhatsAppWidget from 'components/complex/WhatsAppWidget'
import { Loader } from 'components/Loader'

const HomePage = React.lazy(() => import('./pages/Home'))
const CustomerSupportPage = React.lazy(() => import('./pages/CustomerSupport'))
const TransparencyPage = React.lazy(() => import('./pages/Transparency'))
const ReportPracticesPage = React.lazy(() => import('./pages/ReportPractices'))
const CustomerQuerySection = React.lazy(() => import('./pages/CustomerSupport/sections'))

const RechargeQueryFile = React.lazy(() => import('./pages/CustomerSupport/sections/RecargaRetiro'))
const PrivacyPoliciesPage = React.lazy(() => import('./pages/PrivacyPolicies'))
const CustomerPoliticasFile = React.lazy(
  () => import('./pages/CustomerSupport/sections/DownloadContrato/PoliticaPersonales')
)
const CustomerPoliticasPrivacidadFile = React.lazy(
  () => import('./pages/CustomerSupport/sections/DownloadContrato/PoliticasPrivacidad')
)
const CustomerCuentaSimpleFile = React.lazy(
  () => import('./pages/CustomerSupport/sections/DownloadContrato/CuentaSimplicada')
)
const CustomerServiciosFile = React.lazy(
  () => import('./pages/CustomerSupport/sections/ListaServicios/Servicios')
)
const PromotionFile = React.lazy(() => import('./pages/Home/PromotionFile'))

const Trimestre12021 = React.lazy(
  () => import('./pages/Transparency/sections/RegistroReclamos/1Trimestre2021')
)

const Trimestre22021 = React.lazy(
  () => import('./pages/Transparency/sections/RegistroReclamos/2Trimestre2021')
)

const SubsidiosEssalud = React.lazy(
  () => import('./pages/SubsidiosEssalud')
)

const PromocionesCabify = React.lazy(
  () => import('./pages/Promociones/Cabify')
)

const App: FunctionComponent = () => (
  <React.Fragment>
    <WhatsAppWidget />
    <Suspense fallback={<Loader />}>
      <Route path={ROUTES.ROOT} component={HomePage} />
      <Route path={ROUTES.CUSTOMER_SUPPORT_ROOT} component={CustomerSupportPage} />
      <Route
        path={`${ROUTES.CUSTOMER_SUPPORT_ROOT}/:customerUri`}
        component={CustomerQuerySection}
      />

      <Route path={ROUTES.CUSTOMER_SUPPORT_RECARGA_RETIRO} component={RechargeQueryFile} />
      <Route path={ROUTES.CUSTOMER_SUPPORT_CONTRATO_POLITICAS} component={CustomerPoliticasFile} />
      <Route path={ROUTES.CUSTOMER_SUPPORT_CONTRATO_POLITICAS_PRIVACIDAD} component={CustomerPoliticasPrivacidadFile} />
      <Route path={ROUTES.CUSTOMER_SUPPORT_CONTRATO_CUENTA} component={CustomerCuentaSimpleFile} />
      <Route path={ROUTES.CUSTOMER_SUPPORT_SERVICIOS_FILE} component={CustomerServiciosFile} />
      <Route path={ROUTES.TRANSPARENCY_ROOT} component={TransparencyPage} />
      <Route path={`${ROUTES.TRANSPARENCY_ROOT}/:transparencyUri`} component={TransparencyPage} />
      <Route path={ROUTES.REPORT_PRACTICES} component={ReportPracticesPage} />
      <Route path={ROUTES.HOME_PROMOTION_FILE} component={PromotionFile} />
      <Route path={ROUTES.RECLAMOS_TRIMESTRE_1_2021} component={Trimestre12021} />
      <Route path={ROUTES.RECLAMOS_TRIMESTRE_2_2021} component={Trimestre22021} />
      <Route path={ROUTES.SUBSIDIOS_ESSALUD} component={SubsidiosEssalud} />
      <Route path={ROUTES.PROMOCIONES_KONCABIFY} component={PromocionesCabify} />
      <Route path={ROUTES.POLITICAS_DE_PRIVACIDAD} component={PrivacyPoliciesPage} />
    </Suspense>
  </React.Fragment>
)

export default App
